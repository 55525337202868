import React from 'react'


const ProjectData = [
    {
        id: 1,
        title: "Portfolio-V2",
        subtitle:"July 2023",
        description: "Portfolio-v2 is the current website you are viewing right now. It serves as a personal website showcasing my experiences, projects, and a bit about me!",
        techStack: "The website was developed with React, styled with TailwindCSS, animated with Framer-motion, and made with love ❤️",
        image: "Portfolio-v2.webp",
        github: "https://github.com/stephen-ics/portfolio-v2",
        devpost: "",
        youtube: "",
        website: "https://stephenni.com",
        tags: ['ReactJS', 'TailwindCSS', 'Framer-motion'],
    },
    {
        id: 2,
        title: "traCEker",
        subtitle:"June 2023",
        description: "TraCEker is a platform where students can track how often they shower and how well they are doing relative to other users with a monthly leaderboard. Users upload a selfie of them after they shower, which is detected by a classification vision model. Once the selfie is verified as a valid submission, the user is logged as showered. Users can use the calendar view to see how often they showered throughout the days.",
        techStack: "We built traCEker using: React for the frontend website, Python, Firebase, and Flask for the backend, MongoDB to store all our data, Trained Custom Vision using images to detect selfies with Microsoft Azure Custom Vision, Figma to prototype the design",
        image: "traCEker.png",
        github: "https://github.com/edisonqu/jamhacks-7",
        devpost: "https://devpost.com/software/traceker",
        youtube: "https://www.youtube.com/watch?v=iKQ5puwgw-I",
        website: "",
        tags: ['ReactJS', 'TailwindCSS', 'Firebase', 'MongoDB', 'Python', 'Flask', 'Azure'],
    },
    {
        id: 3,
        title: "FoundAIr",
        subtitle:"February 2023",
        description: "FoundAIr is an AI-powered business plan generator! Say goodbye to the stress and frustration of writing a business plan from scratch. All you need is your business idea and a few clicks of a button. Simply enter your idea into the webpage, as well as your name, your budget, and your proposed business name, and the AI will take care of the rest.",
        techStack: "We used Tailwind CSS and React to create the front-end consisting of a landing page and a Typeform. To integrate this with the back end, we used an AWS Lambda function where we called upon the OpenAI API to generate text for the business plan. We formatted a PDF file using the fPDF library and returned it to the user using IPFS peer-to-peer technology.",
        image: "FoundAIr.png",
        github: "https://github.com/edisonqu/foundAIr",
        devpost: "https://devpost.com/software/foundair",
        youtube: "https://www.youtube.com/watch?v=qxRpXTaznIY",
        website: "https://www.foundair.tech/",
        tags: ['ReactJS', 'TailwindCSS', 'Framer-motion', 'Python', 'AWS', 'IPFS'],
    },
    {
        id:4,
        title: "Certuary",
        subtitle:"January 2023",
        description: "Our project was a blockchain-based certificate verification system that was built using IPFS and Estuary. The main goal of the project was to create a secure and tamper-proof way for individuals and organizations to verify the authenticity of certificates",
        techStack: "On the frontend, we used React and Tailwind to build the user interface for our website. On the backend, we used Python and Flask to build the server-side logic and host our website. Estuary provided a decentralized and immutable platform to store and verify the certificates and IPFS was used to create a unique hash of the certificate which was then added to the Estuary blockchain as a new block.",
        image: "Certuary.png",
        github: "https://github.com/stephen-ics/Certuary-Front-End",
        devpost: "https://devpost.com/software/certuary",
        youtube: "https://www.youtube.com/watch?v=J7L8t0qPR0M",
        website: "",
        tags: ['ReactJS', 'TailwindCSS', 'Framer-motion', 'Python', 'Flask'],
    },
    {
        id: 5,
        title: "Portfolio-V1",
        subtitle:"January 2023",
        description: "Portfolio-v1 is the older version of my personal website. It showcases my experiences, projects, and hobbies up until January 2023!",
        techStack: "The website was developed with React, styled with TailwindCSS, animated with Framer-motion, and made with love ❤️",
        image: "Portfolio-v1.jpeg",
        github: "https://github.com/stephen-ics/portfolio",
        devpost: "",
        youtube: "",
        website: "https://portfolio-pearl-beta-96.vercel.app/",
        tags: ['ReactJS', 'TailwindCSS', 'Framer-motion'],
    },
    {
        id: 6,
        title: "DSA Time Complexity Analysis",
        subtitle:"January 2023",
        description: "By systematically varying the input size and recording the corresponding execution times, DSA Time Complexity Analysis provides insight into the scalability and efficiency of different combinations between data structures and algorithms.",
        techStack: "The program was developed with Python",
        image: "DSAAnalysis.png",
        github: "https://github.com/stephen-ics/ICS4U0-DS-A",
        devpost: "",
        youtube: "",
        website: "",
        tags: ['Python'],
    },
    {
        id: 7,
        title: "Hop Queen",
        subtitle:"December 2022",
        description: "Hop Queen is a game that challenges players to ascend to the highest point possible. Inspired by games like Jump King, Hop Queen tests your skills, precision, and patience as you navigate through a vertical world filled with obstacles and platforms.",
        techStack: "Hop Queen was developed on the Unity platform using the C# language",
        image: "HopQueen.png",
        github: "https://github.com/stephen-ics/Hop-Queen",
        devpost: "",
        youtube: "https://www.youtube.com/watch?v=9EW2W5IxRj8",
        website: "",
        tags: ['C#', 'Unity'],
    },
    {
        id: 8,
        title: "Emergenchain",
        subtitle:"October 2022",
        description: "Emergenchain offers a secure and organized way to store one's medical records. Additionally, we also offer a certificate for vaccines and immunizations. Finally, we have a QR code that displays the emergency information tab when scanned. This acts as a precaution for when someone is found unconscious.",
        techStack: "We designed our front end using Figma and coded it on React. All of the medical records are stored DeSo blockchain as posts, and all of our certificates are NFTs minted with DeSo. We also used DeSo login, to implement our login. We minted NFTs on Georli testnet.",
        image: "Emergenchain.gif",
        github: "https://github.com/stephen-ics/Hack-the-Valley",
        devpost: "https://devpost.com/software/health-hub-hi0tmo",
        youtube: "",
        website: "",
        tags: ['ReactJS', 'TailwindCSS', 'Framer-motion', 'SolidityJS', 'DeSo'],
    },
    {
        id: 9,
        title: "EN-FT",
        subtitle:"October 2022",
        description: "Our program allows users seeking to help the environment effectively find volunteering opportunities to do so. We also preserve the action through EN-FT's so good deeds are never forgotten!",
        techStack: "We prototyped on Figma and then developed the front end with Velo. We then used javascript and the DeSo Protocol Library to integrate the authentication, posting, and minting on the Wix's Velo.",
        image: "EN-FT.png",
        github: "",
        devpost: "https://devpost.com/software/en-ft",
        youtube: "https://www.youtube.com/watch?v=DKVj1lv2Es0",
        website: "https://dianalam122.wixsite.com/goldenhacks",
        tags: ['Javascript', 'DeSo'],
    },
    {
        id: 10,
        title: "Just DAO It!",
        subtitle:"September 2022",
        description: "Just DAO It! Provides a framework for people to easily implement decentralized mutual funds. This allows for people to create their own mutual funds, without having to pay a management fee to the bank.",
        techStack: "The UI/UX was designed with Figma. The front end was built on Next.js, the CSS was implemented with TailwindCSS, while the animations were done with framer-motion. We also used various dependencies such as TradingViewWidget to display the graphs. The Web3 backend was coded in Solidity, Javascript, and Typescript. The mutual funds were built with smart contracts, while proposals are made via DAO proposals",
        image: "JustDaoIt.png",
        github: "https://github.com/AritroSaha10/dao-it-frontend",
        devpost: "https://devpost.com/software/just-dao-it",
        youtube: "",
        website: "",
        tags: ['NextJS', 'TailwindCSS', 'Framer-motion', 'Typescript', 'SolidityJS', 'Axelar'],
    },
    {
        id: 11,
        title: "Siri Model",
        subtitle:"August 2022",
        description: "The Siri Model showcases how text processing, language understanding, and information retrieval are employed to enable Siri's conversational capabilities. It covers key concepts such as tokenization, part-of-speech tagging, named entity recognition, sentiment analysis, and intent classification.",
        techStack: "The Siri Model is a Python project developed on Jupyter Notebook that showcases the principles and techniques of Natural Language Processing (NLP)",
        image: "SiriModel.png",
        github: "https://github.com/stephen-ics/Artificial-Intelligence",
        devpost: "",
        youtube: "",
        website: "https://docs.google.com/presentation/d/1L8kr6s6bfznZn8_KiZQn8Xi7ris9Bd324nXH0YK9mDc/edit",
        tags: ['Python', 'Tensorflow', 'CV', 'NLP', 'Neural Networks'],
    },
    {
        id: 12,
        title: "tmun.ca",
        subtitle:"August 2022",
        description: "tmun.ca is an official website for the Toronto Model United Nations (TMUN), an annual three day conference that simulates the United Nations and engages students in diplomacy and global issues. ",
        techStack: "Built using Jekyll, Liquid, Ruby on Rails, SCSS, and Bootstrap, this website offers a comprehensive platform to showcase TMUN's mission, events, and resources.",
        image: "tmun.png",
        github: "https://github.com/TorontoModelUnitedNations/TorontoModelUnitedNations.github.io",
        devpost: "",
        youtube: "",
        website: "https://www.tmun.ca",
        tags: ['HTML5', 'CSS3', 'Javascript', 'Bootstrap', 'SCSS', 'Ruby'],
    },


    {
        id: 13,
        title: "Stock Stalker",
        subtitle:"August 2022",
        description: "Unlike the traditional profit-orientated approach in financial investing, responsible investing is a relatively new concept that expressly recognizes the importance of environmental, social, and governance aspects to the investor and the long-term health and stability of the market",
        techStack: "The prototype was designed with Figma while the front end was built on React. We used Tailwind CSS, framer-motion and various libraries to decorate our web page. The backend data was stored on JSON-Server, cors and axios ·Notifications are sent out using Twilio. Functionalities were built with tradingviewwidget",
        image: "StockStalker.png",
        github: "https://github.com/stephen-ics/Hack-The-6ix",
        devpost: "https://devpost.com/software/stock-stalker-4jrosz",
        youtube: "https://www.youtube.com/watch?v=4a00MDgMPiw",
        website: "https://www.stockstalker.tech/",
        tags: ['ReactJS', 'TailwindCSS', 'Framer-motion', 'JSON-Server', 'Twilio'],
    },
    {
        id: 14,
        title: "PlantUp",
        subtitle:"July 2022",
        description: "At its core, PlantUp is a platform that is meant to make gardening an exciting experience- and we have plenty of features that help realize this.",
        techStack: "At its core, PlantUp is a platform that is meant to make gardening an exciting experience- and we have plenty of features that help realize this.' techStack='We designed the prototype on UI/UX Figma and built the front-end using primarily React.JS, tailwind CSS. We also added framer-motion to sprinkle in a little bit of animations. The data in the back end was stored in JSON databases, and we used HTTPS requests to change the JSON in order to fit our needs We also used Twilio for the messaging component and domain.com for our domain name!",
        image: "PlantUp.png",
        github: "https://github.com/stephen-ics/Backyard-Hacks-3.0",
        devpost: "https://devpost.com/software/plantup",
        youtube: "https://www.youtube.com/watch?v=P6atmRMumSM",
        website: "https://www.iwetmyplants.tech/",
        tags: ['ReactJS', 'TailwindCSS', 'JSON-Server', 'Twilio'],
    },
    {
        id: 15,
        title: "CodeBro",
        subtitle:"July 2022",
        description: "CodeBro is an offline programming suite that allows users to practice both their web design and competitive problem-solving skills.",
        techStack: "We built our front-end using React.Js, Typescript, Workbox and Tailwind CSS for the styling. As we were making a Progressive Web App (PWA), we made an active effort to not use any external servers and databases in order to make sure that CodeBro could operate offline.",
        image: "CodeBro.png",
        github: "https://devpost.com/software/codebro",
        devpost: "https://google.com",
        youtube: "https://www.youtube.com/watch?v=kR89L2mBPg0",
        website: "https://aritrosaha10.github.io/HappyBirthdayMLH-Project/",
        tags: ['ReactJS', 'TailwindCSS', 'Typescript', 'PWA', 'Workbox'],
    },
    {
        id: 16,
        title: "Concerto",
        subtitle:"July 2022",
        description: "Concerto aims to destress the high stress concert buying experience ticket minimize reselling through a standard auctioning system!",
        techStack: "The UI was designed through Figma. The front end was built with React and TailwindCSS, while the pages were routed using Next.js built-in router. Our authentication system was built with firebase auth while the back-end data was stored in firestore and accessed through firebase hooks.",
        image: "Concerto.png",
        github: "https://github.com/stephen-ics/concerto",
        devpost: "https://devpost.com/software/concerto",
        youtube: "https://www.youtube.com/watch?v=x8N0Kp2BD_0",
        website: "",
        tags: ['NextJS', 'TailwindCSS', 'Firebase', 'Twilio'],
    },
    {
        id: 17,
        title: "DeSsert",
        subtitle:"June 2022",
        description: "DeSsert is a decentralized recipe sharing site that allow users to browse through traditional to exotic recipes from cultures all over the world.",
        techStack: "The UI was designed through Figma. We then developed the front end with React and TailwindCSS. The pages were routed using Next.js built-in router while the backend features such as creating and liking a post, and minting a recipe into a NFT was achieved through the DeSo protocol library.",
        image: "DeSsert.png",
        github: "https://github.com/MusaKhan16/DeSsert/tree/main",
        devpost: "https://devpost.com/software/dessert",
        youtube: "https://www.youtube.com/watch?v=C8mK9hKa7Is",
        website: "https://de-ssert.vercel.app/",
        tags: ["NextJS", "TailwindCSS", 'DeSo'],
    },
    {
        id: 18,
        title: "CloseConnect",
        subtitle:"June 2022",
        description: "CloseConnect pairs you with people who live all over the world. This allows for people to tread out of their region and meet others who live in different worlds and to celebrate their different cultures and backgrounds.'",
        techStack: "We built the majority of the front-end using react.js, html5, and css3, we built the navigational bar with react-router-dom, and created alerts with reactjs-alert. On the other hand, we used firebase for the back-end database and log-in/log-out system.",
        image: "CloseConnect.png",
        github: "https://github.com/stephen-ics/CloseConnect",
        devpost: "https://devpost.com/software/close-connect",
        youtube: "https://www.youtube.com/watch?v=TQwx4kbj5IM",
        website: "",
        tags: ["ReactJS", "Firebase"],
    },
    {
        id: 19,
        title: "Tokens For Good",
        subtitle:"June 2022",
        description: "Our project gives people who donate to charity an NFT. This gives people the novelty of possessing limited-time collectables and a sense of pride knowing they supported a good cause, increasing the incentive to donate.",
        techStack: "We designed the UI with Figma and implemented the front end using React.js and Chakra UI. The blockchain was implemented with the DesO utility Library and the login was implemented through DeSo Login",
        image: "TokensForGood.png",
        github: "https://github.com/pane2004/jamhacksapp",
        devpost: "https://devpost.com/software/decharity-gvhxw2",
        youtube: "https://www.youtube.com/watch?v=HIyYHT27UNE",
        website: "",
        tags: ["ReactJS", "Chakra-ui", "DeSo"],
    },
    {
        id: 20,
        title: "BitBots",
        subtitle:"January 2022",
        description: "Bitbots is a program that uses computer vision and artificial intelligence to convert ASL signs into English text or audio, and vice versa. It takes input from a user who finger-spells letters, and the phrase is then interpreted into text or audio.",
        techStack: "We built Speak Out! using Google Teachable Machine and the interactive dashboard using HTML,CSS,Javascript and Netlify. As we used Teachable it allowed us to concentrate a lot more on the data to train to give out more accurate results!'",
        image: "BitBots.png",
        github: "https://github.com/Kelsey379/SignLanguageTranslator",
        devpost: "https://devpost.com/software/bitbots",
        youtube: "",
        website: "",
        tags: ["HTML5", "CSS3", "Javascript", "OpenCV", "Tensorflow"],
    }
];
export default ProjectData